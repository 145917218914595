import React, { useState, useEffect } from "react";
import Style from "./Menu.module.css";
import { Link, useLocation } from "react-router-dom";
import SubMenu from "./SubMenu";

export default function Menu({setModalOpen}) {

    const location = useLocation();
    const [subMenuActive, setSubMenuActive] = useState('');
    const [mobile, setMobile] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);

    // mobile / desktop
    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = () => {
        if (window.innerWidth < 768) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }

    // on location change close mobile menu
    useEffect(() => {
        setMobileMenuActive(false);
    }, [location]);

    return (
        <>
            {(!mobile || (mobile && mobileMenuActive)) && (
                <div className={`${Style.menuCont} ${mobileMenuActive && Style.mobileMenu}`}>
                    <div className={`${Style.menu}`}>
                        <nav>
                            <ul>
                                <li onMouseEnter={() => setSubMenuActive('solutions')}  onClick={() => setSubMenuActive('solutions')}>
                                    <a className={Style.dropDown}>Solutions</a>
                                    <SubMenu setSubMenuActive={setSubMenuActive} classes={`solutions ${subMenuActive == 'solutions' ? 'active' : ''}`}>
                                        <ul>
                                            <li>
                                                <Link to="/solutions/headless-woocommerce">Headless WooCommerce</Link>
                                            </li>
                                            <li>
                                                <Link to="/solutions/system-integration">System Integration</Link>
                                            </li>
                                            <li>
                                                <Link to="/service/fully-managed-hosting">Managed Wordpress Hosting</Link>
                                            </li>
                                        </ul>
                                    </SubMenu>
                                </li>

                                <li onMouseEnter={() => setSubMenuActive('products')}  onClick={() => setSubMenuActive('products')}>
                                    <a className={Style.dropDown}>Products</a>
                                    <SubMenu setSubMenuActive={setSubMenuActive} classes={`products ${subMenuActive == 'products' ? 'active' : ''}`}>
                                        <h3>Gnarly Sync (integrations)</h3>
                                        <ul>
                                            <li>
                                                <Link to="/products/gnarly-sync-unleashed-woocommerce">Unleashed - WooCommerce</Link>
                                            </li>
                                        </ul>
                                        <span className="separator"></span>
                                        <h3>Payment Gateway</h3>
                                        <ul>
                                            <li>
                                                <Link to="/products/gocardless-woocommerce">GoCardless - WooCommerce</Link>
                                            </li>
                                        </ul>
                                    </SubMenu>
                                </li>

                                <li onMouseEnter={() => setSubMenuActive('services')} onClick={() => setSubMenuActive('services')}>
                                    <a className={Style.dropDown}>Services</a>
                                    <SubMenu setSubMenuActive={setSubMenuActive} classes={`services ${subMenuActive == 'services' ? 'active' : ''}`}>
                                        <h3>Software Development</h3>
                                        <ul>
                                            <li>
                                                <Link to="/service/wordpress-development" className="icon icon-developers">Wordpress Plugin & Theme Development</Link>
                                            </li>
                                            <li>
                                                <Link to="/service/react-development" className="icon icon-developers">React Front-end Development</Link>
                                            </li>
                                            <li>
                                                <Link to="/service/api-development" className="icon icon-developers">API Development</Link>
                                            </li>
                                        </ul>
                                        {/* <span className="separator"></span>
                                        <h3>DevOps</h3>
                                        <ul>
                                            <li>
                                                <Link to="/service/aws-services" className="icon icon-developers">AWS Services</Link>
                                            </li>
                                        </ul> */}
                                        <span className="separator"></span>
                                        <h3>Hosting</h3>
                                        <ul>
                                            <li>
                                                <Link to="/service/fully-managed-hosting" className="icon icon-developers">Fully Managed Hosting</Link>
                                            </li>
                                        </ul>
                                    </SubMenu>
                                </li>

                                {/* <li>
                                    <Link to="/our-work">Our Work</Link>
                                </li>

                                <li onMouseEnter={() => setSubMenuActive('resources')}>
                                    <Link to="/resources" className={Style.dropDown}>Resources</Link>
                                    <SubMenu setSubMenuActive={setSubMenuActive} classes={`resources ${subMenuActive == 'resources' ? 'active' : ''}`}>
                                        <h3>Tech Insight</h3>
                                        <ul>
                                            <li>
                                                <Link to="/resources?insight-for=marketers" className="icon icon-marketers">For Marketers</Link>
                                            </li>
                                            <li>
                                                <Link to="/resources?insight-for=founders" className="icon icon-founders">For Founders</Link>
                                            </li>
                                            <li>
                                                <Link to="/resources?insight-for=developers" className="icon icon-developers">For Developers</Link>
                                            </li>
                                        </ul>
                                        <span className="separator"></span>
                                        <h3>Documentation</h3>
                                        <ul>
                                            <li>
                                                <Link to="/resources/documentation/gnarly-sync-unleashed-woocommerce">Gnarly Sync</Link>
                                            </li>
                                        </ul>
                                    </SubMenu>
                                </li> */}

                                <li>
                                    <Link to="/contact">Contact</Link>
                                </li>

                                {/* <li>
                                    <a href="https://www.linkedin.com/company/gnarsoftware" target="_blank" rel="noreferrer">
                                        <img src={require('../../assets/linkedIn_icon.svg').default} alt="linkedin" className={Style.socialIcon} />
                                    </a>
                                </li> */}

                                {mobileMenuActive &&
                                    <a className={Style.burgerClose} onClick={() => {setMobileMenuActive(!mobileMenuActive); setSubMenuActive('')}}>
                                        <img src={require('../../assets/close_cross.svg').default} alt="close" />
                                    </a>
                                }
                            </ul>
                        </nav>
                    </div>
                </div> 
            )}

            {mobile && (
                <>
                    <a className={Style.burger} onClick={() => setMobileMenuActive(!mobileMenuActive)}>
                        <img src={require('../../assets/burger_menu.svg').default} alt="burger" />
                    </a>
                </>
            )}
        </>
    );
}