import React from 'react';
import { Link } from 'react-router-dom';
import Theme from '../theme.module.css';
import Content from '../layouts/Content';
import Hero from '../features/hero/Hero';
import Title from '../features/title/Title';
import ContentSubTitle from '../features/title/ContentSubTitle';
import ContactForm from '../features/contactForm/ContactForm';
import { Helmet } from 'react-helmet-async';

export default function ContactPage() {

    return (
        <>
            <Helmet>
                <title>Contact Us | Gnar Software</title>
            </Helmet>

            <Hero classes="services-main-hero">
                <Title title="" subTitle="Contact Us" />
            </Hero>

            <section className={`text-section ${Theme.darkerBack}`}>
                <Content>
                    <div className={Theme.columns}>
                        <div>
                            <p>If you want to discuss your project, or pricing for any of our development services, please get in touch. We're happy to chat!</p>
                            <br /><br />
                            <p><a href="mailto:info@gnar.co.uk" className={Theme.clickToMail}>info@gnar.co.uk</a></p>
                            <p><a href="tel:01483361151" className={Theme.clickToCall}>+44 (0) 1483 361 151</a></p>
                            <p className={Theme.address}>12b Market Street, Guildford, Surrey, GU1 4LB</p>
                            <div className={Theme.spacerBottom}></div>

                            <ContentSubTitle title="Submit an enquiry" />
                            <ContactForm />
                            <div className={Theme.spacerBottom}></div>
                        </div>
                        <div>

                        </div>
                    </div>
                </Content>
            </section>
        </>
    );
}