import React from "react";
import Hero from "../features/hero/Hero";
import Title from "../features/title/Title";
import Content from "../layouts/Content";
import ContentSubTitle from "../features/title/ContentSubTitle";
import IconContent from "../features/iconContent/IconContent";
import { Helmet } from 'react-helmet-async';
import Theme from "../theme.module.css";
import "./Services.css";

export default function ReactDevelopment() {


    return (
        <>
            <Helmet>
                <title>React Development | Gnar Software</title>
                <meta name="description" content="Pixel perfect, highly optimised and the most modern UX. Choose a React based User Interface for your next project to give your users and customers an app like experience, achieve unrivalled core-web-vitals, and ensure your tech is scalable and future proofed." />
            </Helmet>

            <Hero classes="services-main-hero">
                <Title title="Service: Software Development" subTitle="React Front-End Development" />
            </Hero>

            <section className={`text-section ${Theme.darkerBack}`}>
                <Content>
                    <p><b>We are experienced React Developers.</b></p>
                    <p>We offer front-end development for websites and applications. Pixel perfect, highly optimised and the most modern UX. Choose a React based User Interface for your next project to give your users and customers an app like experience, achieve unrivalled core-web-vitals, and ensure your tech is scalable and future proofed.</p>
                    <br/><br/>

                    <ContentSubTitle title="Get in touch" />
                    
                    <p>If you want to discuss your project, or pricing for any of our development services, please get in touch. We're happy to chat!</p>

                    <br/><br/>

                    <a href="tel:01483361151" className={Theme.clickToCall}>+44 (0) 1483 361 151</a>

                    <div className={Theme.spacerBottom}></div>

                </Content>
            </section>
        </>
    );
}