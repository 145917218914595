import React from "react";
import Hero from "../features/hero/Hero";
import Title from "../features/title/Title";
import Content from "../layouts/Content";
import ContentSubTitle from "../features/title/ContentSubTitle";
import IconContent from "../features/iconContent/IconContent";
import Theme from "../theme.module.css";
import { Helmet } from 'react-helmet-async';
import "./GnarlySyncUnleashed.css";

export default function GnarlySyncUnleashed() {


    return (
        <>
            <Helmet>
                <title>Unleashed WooCommerce Integration | Gnar Software</title>
                <meta name="description" content="Sync product stock, customers & orders between Unleashed & Woocommerce with Gnarly Sync." />
            </Helmet>

            <Hero classes="gnarly-sync-main-hero">
                <Title title="Product: Gnarly Sync" subTitle="Unleashed WooCommerce Integration">
                    <img src={require('../assets/unleashed-logo-box.svg').default} alt="Unleashed Software logo" className="unleashed-logo-box" />
                </Title>
            </Hero>

            <section className={`text-section ${Theme.darkerBack}`}>
                <Content>
                    <p><b>Sync product stock, customers & orders between Unleashed & Woocommerce.</b></p>
                    <p>Seemlessly integrate your WooCommerce store or stores with Unleashed Inventory Management System. We will provide a bespoke and tailored integration that meets your individual business requirements.</p>
                    <br/><br/>

                    <div className={`${Theme.flexRow} ${Theme.topicQuestionCont}`}>
                        <a href="https://www.unleashedsoftware.com/en-gb/campaigns/brand" target="_blank" className={Theme.topicQuestion}>What is Unleashed?</a>
                    </div>

                    <ContentSubTitle title="Features" />

                    <IconContent>
                        <div>
                            <img src={require('../assets/icon-link.svg').default} alt="link-icon" />
                            <p>Bespoke Integration, custom mapping, syncing frequency</p>
                        </div>
                        <div>
                            <img src={require('../assets/icon-package.svg').default} alt="package-icon" />
                            <p>Supports Multiple Warehouses</p>
                        </div>
                        <div>
                            <img src={require('../assets/icon-users.svg').default} alt="users-icon" />
                            <p>Full support provided throughout your subscription</p>
                        </div>
                        <div>
                            <img src={require('../assets/icon-thumbs-up.svg').default} alt="thumbs-up-icon" />
                            <p>14 Day Free Trial. No contracts or minimum term</p>
                        </div>
                    </IconContent>

                    <ContentSubTitle title="Pricing" />

                    <div className={`${Theme.flexRow} gnarly-sync-pricing-cont`}>
                        <div className={`${Theme.col} pricing-col-1`}>
                            <h4>£90.00 <span>per month (ex vat)</span></h4> 
                            <p className="pricing-note">Please contact us if you have multiple stores for pricing</p>
                        </div>
                        <div className={`${Theme.col} pricing-col-2`}>
                            <p><b>What's included?</b></p>
                            <ul className={Theme.contentBullets}>
                                <li>Our main integration plugin</li>
                                <li>Bespoke integration tailoring (via a secondary plugin)</li>
                                <li>Setup support</li>
                                <li>Support throughout the subscription</li>
                            </ul>
                        </div>
                    </div>

                    <ContentSubTitle title="Contact Us or Sign Up" />
                    
                    <p>If you have any questions, or want to discuss the details of your requirements with us please contact us directly. Otherwise you can sign up and get started below. We will contact you upon receiving your order to arrange setup of your integration, alternatively if you're a developer you will be able to download the plugin after ordering.</p>

                    <br/><br/>

                    <a href="tel:01483361151" className={Theme.clickToCall}>+44 (0) 1483 361 151</a>

                    <br/><br/>

                    <a href="https://store.gnar.co.uk/products/cart/?add-to-cart=7003&quantity=1" target="_blank" className={`${Theme.greenButton} sign-up-btn`}>Sign up</a>

                    <div className={Theme.spacerBottom}></div>

                </Content>
            </section>
        </>
    );
}