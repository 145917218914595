import React from "react";
import Hero from "../features/hero/Hero";
import Title from "../features/title/Title";
import Content from "../layouts/Content";
import ContentSubTitle from "../features/title/ContentSubTitle";
import IconContent from "../features/iconContent/IconContent";
import { Helmet } from 'react-helmet-async';
import Theme from "../theme.module.css";
import "./SystemIntegration.css";

export default function SystemIntegrationPage() {



    return (
        <>
            <Helmet>
                <title>System Integration | Gnar Software</title>
                <meta name="description" content="In today's age of distributed SaaS solutions, the ability to streamline processes by ensuring synergy between the systems is paramount to achieving sustained growth, cost reduction, and operational efficiency." />
            </Helmet>

            <Hero classes="headless-woocom-main-hero">
                <Title title="Solution: System Integration" subTitle="Reduce manual processing by connecting your software services" />
            </Hero>

            <section className={`text-section ${Theme.darkerBack}`}>
                <Content>
                    <p><b>In today's age of distributed SaaS solutions, the ability to streamline processes by ensuring synergy between the systems is paramount to achieving sustained growth, cost reduction, and operational efficiency.</b></p>
                    <br/><br/>
                    <p>We understand that proper integration of your organization's systems is not just about connecting software; it's about creating a cohesive ecosystem that fosters productivity and empowers your team to focus on what matters most—innovation and growth. Whether you're grappling with disparate ERP (Enterprise Resource Planning) platforms, disjointed CRM (Customer Relationship Management) systems, or fragmented PIM (Product Information Management) databases, we have the expertise to harmonize them all seamlessly.</p>
                    <br/><br/>

                    <ContentSubTitle title="Systems" />

                    <p>Some of the many systems that can be connected together via API integration to reduce your manual processes:</p>
                    <br/><br/>

                    <IconContent>
                        <div>
                            <img src={require('../assets/icon-globe.svg').default} alt="globe-icon" />
                            <p>ERP <br/>(Enterprise Resource Planning)</p>
                        </div>
                        <div>
                            <img src={require('../assets/icon-users.svg').default} alt="package-users" />
                            <p>CRM <br/>(Customer Relationship Management)</p>
                        </div>
                        <div>
                            <img src={require('../assets/icon-data.svg').default} alt="icon-data" />
                            <p>PIM <br/>(Product Information Management)</p>
                        </div>
                        <div>
                            <img src={require('../assets/icon-credit-card.svg').default} alt="icon-credit-card" />
                            <p>Payments and Shipping Services</p>
                        </div>
                        <div>
                            <img src={require('../assets/icon-dataflow.svg').default} alt="store-dataflow" />
                            <p>Omnichannel Sales</p>
                        </div>
                        <div>
                            <img src={require('../assets/icon-package.svg').default} alt="icon-package" />
                            <p>Stock Management</p>
                        </div>
                    </IconContent>

                    <div className={Theme.spacerBottom}></div>

                </Content>
            </section>
        </>
    );
}