import React, { useEffect, useState } from "react";
import Content from "../../layouts/Content";
import Theme from "../../theme.module.css";
import "./Hero.css";

export default function HomeHero({classes, children, outsideChildren = null}) {

    return (
        <section className={`${Theme.section} hero ${classes}`}>
            <Content>
                {children}
            </Content>
            {outsideChildren}
        </section>
    );
}