import React from "react";
import Hero from "../features/hero/Hero";
import Title from "../features/title/Title";
import Content from "../layouts/Content";
import ContentSubTitle from "../features/title/ContentSubTitle";
import IconContent from "../features/iconContent/IconContent";
import { Helmet } from 'react-helmet-async';
import Theme from "../theme.module.css";
import "./Services.css";

export default function ApiDevelopment() {


    return (
        <>
            <Helmet>
                <title>API Development Services | Gnar Software</title>
                <meta name="description" content="API development, integration development and application architecture consultation service." />
            </Helmet>

            <Hero classes="services-main-hero">
                <Title title="Service: Software Development" subTitle="API Development" />
            </Hero>

            <section className={`text-section ${Theme.darkerBack}`}>
                <Content>
                    <p><b>API development, integration development and application architecture consultation service.</b></p>
                    <p>We understand the power of connectivity in today's digital landscape. Our API development and integration services are designed to empower your business by creating robust and scalable connections between your applications, systems, and external services.</p>
                    <br/><br/>

                    <ContentSubTitle title="Get in touch" />
                    
                    <p>If you want to discuss your project, or pricing for any of our development services, please get in touch. We're happy to chat!</p>

                    <br/><br/>

                    <a href="tel:01483361151" className={Theme.clickToCall}>+44 (0) 1483 361 151</a>

                    <div className={Theme.spacerBottom}></div>

                </Content>
            </section>
        </>
    );
}