import React from "react";
import { Link } from "react-router-dom";
import Menu from "../features/menu/Menu";
import Theme from "../theme.module.css";
import "./Header.css";


export default function Header() {

    return (
        <header>
            <div className={Theme.flexRow}>
                <Link to="/">
                    <img src={require('../assets/gnar-software-white.png')} alt="Gnar Software" class="gnar-logo"/>
                </Link>

                <Menu />
            </div>
        </header>
    );
}