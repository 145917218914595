import React from "react";
import Theme from '../theme.module.css';

function Content({children}) {

    return(
        <div className={Theme.contentWrapper}>
            <div className={`${Theme.content} content-width`}>
                {children}
            </div>
        </div>
    );
}

export default Content;