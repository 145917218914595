import React from "react";
import Hero from "../features/hero/Hero";
import Title from "../features/title/Title";
import Content from "../layouts/Content";
import Style from "./GoCardless.module.css";
import Theme from "../theme.module.css";
import { Helmet } from 'react-helmet-async';
import "./GnarlySyncUnleashed.css";

export default function GoCardlessPage() {


    return (
        <>
            <Helmet>
                <title>GoCardless Instant Bank Pay for WooCommerce | Gnar Software</title>
                <meta name="description" content="Take instant bank payments on your WooCommerce store through open banking technology. Increase your conversions, reduce fees, reduce failed payments and modernise your customers checkout experience." />
            </Helmet>

            <Hero classes="gnarly-sync-main-hero">
                <Title title="Product: Payment Gateway Integration" subTitle="GoCardless Instant Bank Pay for WooCommerce">
                </Title>
            </Hero>

            <section className={`text-section ${Theme.darkerBack}`}>
                <Content>
                    <div className={`${Theme.flexRow} ${Style.topSection}`}>
                        <div>
                            <p><b>Take instant bank payments on your WooCommerce store through open banking technology. Increase your conversions, reduce fees, reduce failed payments and modernise your customers checkout experience.</b></p>
                            <ul className={Theme.bulletList}>
                                <li>Lower fees: Typically 54% cheaper than online card transactions.</li>
                                <li>Instant Confirmation: This bank-to-bank payment offers real-time confirmation for you and your payers.</li>
                                <li>Optimise payer conversion: Frictionless authorisation and no need to enter card details.</li>
                                <li>Save admin time: Spend less time chasing down late or failed payments.</li>
                            </ul>
                            <p>GoCardless instant bank payment is currently available to customers in the UK and Germany. Simple setup with Sandbox testing mode. Customers complete the transaction without leaving your checkout page.</p>
                            <br/><br/>

                            <div className={`${Theme.flexRow} ${Theme.topicQuestionCont}`}>
                                <a href="https://gocardless.com/solutions/instant-bank-pay" target="_blank" className={Theme.topicQuestion}>What is an instant bank payment?</a>
                            </div>

                            <div className={Theme.spacerBottom}></div>

                            <a href="https://downloads.wordpress.org/plugin/wc-gocardless-instant-bank-payments.1.3.0.zip" className={Theme.greenButton}>download</a>
                        </div>
                        <div>
                            <img src={require('../assets/ibp-link-customer-en-gb.png')} alt="gocardless-mobile" />
                        </div>
                    </div>

                    <div className={Theme.spacerBottom}></div>

                </Content>
            </section>
        </>
    );
}