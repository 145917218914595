import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useLocation, Navigate } from 'react-router-dom';
import "./animations.css";
import Home from "./pages/Home";
import Menu from './features/menu/Menu';
import SolutionsPage from './pages/SolutionsPage';
import ProductsPage from './pages/ProductsPage';
import ContactPage from './pages/ContactPage';
import Modal from './layouts/Modal';
import ContactForm from './features/contactForm/ContactForm';
import AboutPage from './pages/About';
import OurWorkPage from './pages/OurWorkPage';
import { AnimatePresence } from 'framer-motion';
import CaseStudyMainRaceworks from "./features/ourWork/CaseStudies/CaseStudyMainRaceworks";
import CaseStudyMainOdysseyMedia from "./features/ourWork/CaseStudies/CaseStudyMainOdysseyMedia";
import CaseStudyMainABRFestival from "./features/ourWork/CaseStudies/CaseStudyMainABRFestival";
import Header from './layouts/Header';
import Theme from './theme.module.css';
import Footer from './layouts/Footer';
import Resource from './pages/Resource';
import NotFound from './pages/NotFound';
import HeadlessWooCommercePage from './pages/HeadlessWooCommerce';
import SystemIntegrationPage from './pages/SystemIntegration';
import ManagedHostingPage from './pages/ManagedHosting';
import GnarlySyncUnleashed from './pages/GnarlySyncUnleashed';
import WordpressDevelopment from './pages/WordpressDevelopment';
import ReactDevelopment from './pages/ReactDevelopment';
import ApiDevelopment from './pages/ApiDevelopment';
import Documentation from './pages/Documentation';
import { HelmetProvider } from "react-helmet-async";
import GoCardlessPage from './pages/GoCardless';


function App() {

  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  const contactForm = <ContactForm setModalOpen={setModalOpen} />;
  const caseStudyMainRaceworks = <CaseStudyMainRaceworks />;
  const caseStudyMainOdyssey = <CaseStudyMainOdysseyMedia />;
  const caseStudyMainABRFestival = <CaseStudyMainABRFestival />;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location])

  return (
    <div className={Theme.outerWrap}>
      <Header />
        <AnimatePresence mode="sync">
          <HelmetProvider>
            <Routes location={location} key={location.pathname}>
                <Route path="/solutions/headless-woocommerce" element={<HeadlessWooCommercePage />} key="solution-headless-woocommerce"/>
                <Route path="/solutions/system-integration" element={<SystemIntegrationPage />} key="solution-system-integration"/>
                <Route path="/products/gocardless-woocommerce" element={<GoCardlessPage />} key="go-cardless"/>
                {/* <Route path="/solutions/fully-managed-hosting" element={<ManagedHostingPage />} key="solution-managed-aws-hosting"/> */}
                <Route path="/products/gnarly-sync-unleashed-woocommerce" element={<GnarlySyncUnleashed />} key="gnarly-sync-unleashed-woocommerce"/>
                <Route path="/service/wordpress-development" element={<WordpressDevelopment />} key="wordpress-development"/>
                <Route path="/service/react-development" element={<ReactDevelopment />} key="react-development"/>
                <Route path="/service/api-development" element={<ApiDevelopment />} key="api-development"/>
                <Route path="/service/fully-managed-hosting" element={<ManagedHostingPage />} key="fully-managed-hosting"/>
                {/* <Route path="/our-work" element={<OurWorkPage setModalOpen={setModalOpen} modalOpen={modalOpen} />} key="our-work"/> */}
                {/* <Route path="/resources/documentation/:slug" element={<Documentation />} key="documentation"/>
                <Route path="/resources/:slug" element={<Resource />} key="resource"/> */}
                <Route path="/contact" element={<ContactPage />} key="contact"/>
                <Route path="/" element={<Home />} key="home"/>
                <Route path="*" element={<NotFound />} />
            </Routes>
          </HelmetProvider>
        </AnimatePresence>
      <Footer />

      {modalOpen == 'contact-form' && 
        <Modal modalInner={contactForm} setModalOpen={setModalOpen} slug={'contact-form'}/>
      }
      {modalOpen == 'the-raceworks-internationalisation' &&
        <Modal modalInner={caseStudyMainRaceworks} setModalOpen={setModalOpen} slug={'the-raceworks-internationalisation'} />
      }
      {modalOpen == 'odyssey-media-bed' &&
        <Modal modalInner={caseStudyMainOdyssey} setModalOpen={setModalOpen} slug={'odyssey-media-bed'} />
      }
      {modalOpen == 'abr-festival' &&
        <Modal modalInner={caseStudyMainABRFestival} setModalOpen={setModalOpen} slug={'abr-festival'} />
      }
    </div>
  );
}

export default App;
