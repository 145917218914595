import React from "react";

export default function CaseStudyMainOdysseyMedia() {

    return (
        <div className='case-study-main'>
            <h1>Odyssey Media</h1>
            <img src={require('../../../assets/odyssey-media-2.jpg')} alt="odyssey-media-client" style={{width: '100%', maxHeight: '40%'}}/>

            <h2>The Challenge</h2>
            <p>We worked with Odyssey Media to deliver a complex ecommerce website for their client a bed and mattress vendor. The main challenge presented was management of their complex configurable product data. Products had up to 7 configurable attributes, with millions of possible configurations.</p>

            <h2>The Solution</h2>
            <p>We designed a solution that would suit the product data, ensuring that it was flexible enough to handle products that may require different handling in the future, but also be performant enough to not negatively effect UX. We built a custom theme, implementing efficient cataloguing / product filtering, complex custom product configuration and add to cart process. The non-templated pages were built in Gutenberg with custom blocks to ensure the client could easily update and maintain content.</p>
        </div>
    )
}