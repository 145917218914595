import React from "react";
import Content from "../layouts/Content";
import SignUp from "../features/signUp/SignUp";
import Theme from "../theme.module.css";
import { Link } from "react-router-dom";
import "./Footer.css";

export default function Footer() {

    return (
        <footer>
            <Content>
                <div className="footer-sign-up-cont">
                    <SignUp classes="footer-sign-up" text="Recieve the latest technical insights" />
                </div>
                <div className={Theme.flexRow}>
                    <Link>
                        <img src={require('../assets/gnar-software-white.png')} alt="Gnar Software" class="gnar-logo"/>
                    </Link>
                    <div className="footer-col">
                        <h4>Solutions</h4>
                        <ul>
                            <li>
                                <Link to="/solutions/headless-woocommerce">Headless WooCommerce</Link>
                            </li>
                            <li>
                                <Link to="/solutions/system-integration">System Integration</Link>
                            </li>
                            <li>
                                <Link to="/service/fully-managed-hosting">Managed Wordpress Hosting</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="footer-col">
                        <h4>Products</h4>
                        <ul>
                            <li>
                                <Link to="/products/gnarly-sync-unleashed-woocommerce">Unleashed - WooCommerce</Link>
                            </li>
                            <li>
                                <Link to="/products/gocardless-woocommerce">GoCardless - WooCommerce</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="footer-col">
                        <h4>Services</h4>
                        <ul>
                            <li>
                                <Link to="/service/wordpress-development">Wordpress Plugin & Theme Development</Link>
                            </li>
                            <li>
                                <Link to="/service/react-development">React Front-end Development</Link>
                            </li>
                            <li>
                                <Link to="/service/api-development">API Development</Link>
                            </li>
                            <li>
                                <Link to="/service/fully-managed-hosting">Fully Managed Hosting</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="footer-col">
                        <h4>Company</h4>
                        <ul>
                            {/* <li>
                                <Link to="/about">Insights</Link>
                            </li>
                            <li>
                                <Link to="/about">Documentation</Link>
                            </li>
                            <li>
                                <Link to="/about">Our Work</Link>
                            </li> */}
                            <li>
                                <Link to="/contact">Contact Us</Link>
                            </li>
                            <li>
                                <Link to="/privacy-policy">Privacy Policy</Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <p className="footer-copyright">© Copyright Gnar Software Ltd 2024</p>
            </Content>
        </footer>
    );
}