import React from "react";
import "./title.css";

export default function Title({title, subTitle, classes, children}) {

    return (
        <div className={`title-block ${classes}`}>
            <h1>{title}</h1>
            <h2>{subTitle}</h2>
            {children ? children : null}
        </div>
    )
}