import React from "react";

export default function CaseStudyMainABRFestival() {
        
    return (
        <div className='case-study-main'>
            <h1>ABR Festival</h1>

            <div className="ytIframeCont">
                <iframe width="100%" src="https://www.youtube.com/embed/Cw4LGYeygaw?si=bDuFQlQfmI_-T04P" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen className="ytFframe"></iframe>
            </div>


            <h2>Challenge</h2>
            <p>Adventure Bike Rider had huge success with their festival without over 10,000 tickets sold, but had experienced issues with peak traffic and server down time whilst launching the ticket sales. They were using The Events Calendar Tickets plugin for Wordpress & Woocommerce – a cost effective solution, but not one built for scale or performance.</p>
        
            <h2>Solution</h2>
            <p>In order to keep cost to the client low, and reduce the requirement for major changes, we built a custom extension to the Events Calender plugin that streamlined the processes at a code level – bypassing bloated and unnecessary database queries, particularly those surrounding the stock management of the tickets. This on it’s own removed the requirement for horizontal scaling, or use of an expensive saas ticketing solution.</p>
        
        </div>
    )
}