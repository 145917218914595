import React, {useState} from "react";
import axios from "axios";
import Theme from "../../theme.module.css";


export default function SignUp({classes, text}) {

    const client = axios.create({
        withCredentials: true
    })

    const contactServiceUrl = 'https://store.gnar.co.uk/wp-json/gshw/v1/sign-up';
    // const contactServiceUrl = 'http://localhost/kadamentwp1/wp-json/gshw/v1/sign-up';
    const [validationMsg, setValidationMsg] = useState('');

    const [fields, setFields] = useState({
        email: ''
    });

    function handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        setFields({...fields, [name]: value});
    }

    async function handleSubmit(event) {
        event.preventDefault();

        console.log(fields);

        try {
            const response = await client.post(contactServiceUrl, fields);
            console.log(response.data);
      
            // Reset the form
            setFields({
              email: ''
            });

            // show success message
            setValidationMsg('Thanks for signing up!');
            

        } catch (error) {
            // Handle any errors
            console.error(error);
            setValidationMsg('Sorry, there was a problem signing you up. Please email us at info@gnar.co.uk');
        }
    }

    return (
        <div className={classes}>
            <p>{text}</p>
            <form onSubmit={handleSubmit}>
                <label>
                    <input type="email" name="email" onChange={handleChange} value={fields.email} placeholder="your email"/>
                </label>
                <div className="submit-cont">
                    <input type="submit" className={Theme.greenButton} style={{marginTop: "20px", display: "inline-block"}} value="sign up"/>
                </div>

                {validationMsg && 
                    <p className="contactFormValidationMsg">{validationMsg}</p>
                }
            </form>
        </div>
    );
}