import React from "react";
import Hero from "../features/hero/Hero";
import Title from "../features/title/Title";
import Content from "../layouts/Content";
import ContentSubTitle from "../features/title/ContentSubTitle";
import IconContent from "../features/iconContent/IconContent";
import HostingConfigurator from "../features/hostingConfigurator/HostingConfigurator";
import { Helmet } from 'react-helmet-async';
import Theme from "../theme.module.css";
import "./ManagedHosting.css";

export default function ManagedHostingPage() {



    return (
        <>
            <Helmet>
                <title>Fully Managed VPS Hosting for Wordpress | Gnar Software</title>
                <meta name="description" content="Fully managed virtual private server hosting ideal for Wordpress & WooCommerce. The most secure, fast and flexible option for your Wordpress site served with a dedicated technical account manager to ensure that your server is setup optimally for your site always" />
            </Helmet>

            <Hero classes="service-hosting-main-hero">
                <Title title="Service: Hosting" subTitle="Fully Managed VPS & Dedicated Hosting for Wordpress & WooCommerce" />
            </Hero>

            <section className={`text-section ${Theme.darkerBack}`}>
                <Content>
                    <p><b>The most secure, fast and flexible option for your Wordpress site served with a dedicated technical account manager to ensure that your server is setup optimally for your site always.</b></p>
                    <p>Virtual private servers (VPS), and dedicated servers provide a much higher level of security and flexibility over cheaper shared hosting options. We provide you and your developers with full SSH access, round the clock support (PHP version upgrades, DNS, email sending etc), and will migrate your site to your new server free of charge.</p>
                    <br/><br/>

                    <div className={`${Theme.flexRow} ${Theme.topicQuestionCont}`}>
                        <a className={Theme.topicQuestion}>What is a VPS?</a>
                        <a className={Theme.topicQuestion}>What is a dedicated server?</a>
                    </div>

                    <div className={Theme.spacerBottom}></div>
                </Content>
            </section>

            {/* <section className={`text-section ${Theme.lightBack}`}>
                <Content>
                    <ContentSubTitle title="Configure your server" classes="light-back" />

                    <HostingConfigurator />

                    <div className={Theme.spacerBottom}></div>
                </Content>
            </section> */}
        </>
    );
}