import React, { useRef } from "react";
import Hero from '../features/hero/Hero';
import Title from "../features/title/Title";
import Theme from "../theme.module.css";
import { Link } from "react-router-dom";
import "../animations.css";
import "./Home.css";
import AnimateSection from "../features/animateSection/AnimateSection";
import Content from "../layouts/Content";
import GoCardless from "../features/products/slides/GoCardless";
import SimpleCarousel from "../features/simpleCarousel/SimpleCarousel";
import { Helmet } from 'react-helmet-async';


export default function Home() {

    const sectionRef = useRef(null);

    let clientLogoSlides = [
        <a href=""><img src={require('../assets/evince-160-@2x.png')} alt="Evince logo" /></a>,
        <a href=""><img src={require('../assets/theraceworks-logo.png')} alt="The Raceworks logo" /></a>,
        <a href=""><img src={require('../assets/odyssey-media-logo.svg').default} alt="Odyssey Media logo" /></a>,
        <a href=""><img src={require('../assets/unleashed-logo.webp')} alt="Unleashed logo" /></a>,
        <a href=""><img src={require('../assets/abr-logo.png')} alt="Adventure Bike Rider logo" /></a>,
        <a href=""><img src={require('../assets/Gocardless-blue.svg').default} alt="GoCardless logo" /></a>,
    ]

    let clientLogoSlides2 = [
        <a href=""><img src={require('../assets/dellavite-logo-2.svg').default} alt="Dellavite logo" /></a>,
        <a href=""><img src={require('../assets/drpg-logo.svg').default} alt="DRP Group logo" className="drpg-logo"/></a>,
        <a href=""><img src={require('../assets/obsession-content-logo.png')} alt="Obsession Content logo" /></a>,
        <a href=""><img src={require('../assets/unicycle-logo-white.svg').default} alt="Unicycle logo" /></a>,
        <a href=""><img src={require('../assets/context-consulting-logo.png')} alt="Context Consulting logo" /></a>,
        <a href=""><img src={require('../assets/lime-logo.png')} alt="Lime marketing agency logo" /></a>,
    ]

    return (
        <>
            <Helmet>
                <title>Gnar Software | Software Development & Solutions</title>
                <meta name="description" content="Software Solutions & Development Services based in Guildford" />
            </Helmet>

            <Hero classes="home-main-hero" outsideChildren={
                <img src={require('../assets/raceworks-f1-laptop.png')} alt="headless-woocommerce-example" className="raceworks-cut-off" />
                }>
                <Title title="Gnar Software" subTitle="Software Development & Solutions" />
            </Hero>

            <section className="home text-section">
                <Content>
                    <p><b>Software Solutions & Development Services based in Guildford</b></p>
                    <p>We Offer: Software development services, headless WooCommerce site development, WooCommerce scaling and internationalisation, custom WordPress plugin and theme development, bespoke integrations, React development, API development, AWS services, CI/CD pipeline development, fully managed hosting and more!</p>
                    <br/><br/><br/>
                    <p className="our-clients-text"><b>Our clients and partners</b></p>
                    <br/><br/>
                    <SimpleCarousel slides={clientLogoSlides} numSlidesDesktop={6} numSlidesMobile={3} />
                    <SimpleCarousel slides={clientLogoSlides2} numSlidesDesktop={6} numSlidesMobile={3} />
                </Content>
            </section>

            <Hero classes="home-gocardless-hero">
                <GoCardless />
            </Hero>

            <Hero classes="home-gnarlysync-hero">
                <Title title="Gnarly Sync" subTitle="Unleashed WooCommerce Integration">
                    <Link to="/products/gnarly-sync-unleashed-woocommerce" className={`${Theme.greenButton} orangeButton`}>discover</Link>
                </Title>
                <img src={require('../assets/unleashed.png')} alt="Unleashed logo" class="unleashed-logo" />
            </Hero>

            <Hero classes="home-headlesswoocom-hero">
                <div className="text-center">
                    <img src={require('../assets/raceworks-mobile.png')} alt="headless-woocommerce-example" className="raceworks-mobile" />
                </div>
                <Title title="Headless WooCommerce" subTitle="WooCommerce Scaling & Internationalisation">
                    <p>Unlock international markets, or scale for demand, with our headless Woocommerce implementation.</p>
                    <Link to="/solution/headless-woocommerce" className={Theme.greenButton}>discover</Link>
                </Title>
            </Hero>
        </>
    );
}