import React, {useRef, useEffect} from "react";

export default function CaseStudyMainRaceworks() {

    const vidRef = useRef(null);

    useEffect(() => {
        vidRef.current.play();
    }, [])
    

    return (
        <div className='case-study-main'>
            <h1>The Raceworks ecommerce Internationalisation</h1>
            
            <video src={require('../../../assets/video/trw-eu-demo-1.mkv')} ref={vidRef}></video>

            <h2>The Challenge</h2>
            <p>The Raceworks business was thriving in the UK through after extending their business to B2C, but they wanted to take it to the next level and extend to Europe. Their existing website was a traditional Woocommerce site with ERP, and channel integrations.</p>
            <p>Whilst WooCommerce is a fantastic solution, it’s not designed to manage stock across multiple warehouses, support translated stock data and as a monolith it inherently won’t support multiple localised front-ends.</p>

            <h2>The Solution</h2>
            <p>To avoid a platform change, we elected for a headless woocommerce solution.</p>
            <p>We built:</p>
            <ul>
                <li>A beautiful and modern shopfront in React. One single code base with dynamically localised features, but deployed as separate regional sites for SEO purpose.</li>
                <li>A Wordpress plugin exposing our own API. This gave us more granular control over access, schema and performance over the standard Woocomerce API</li>
                <li>A headless Stripe payment gateway integration (including express checkout with handling for localised tax and shipping rates)</li>
                <li>A Woocommerce extension to automatically batch translate product data using Google Translate API V3.</li>
                <li>A fully automated delivery pipeline. The application is containerised and deployed with the localised front ends deployed as separate services using AWS ECS across EC2 instances, provisioned with IAC, in multiple availability zones.</li>
                <li>A separate in-house web service for automatically translating static website content at build time, again using Google Translate API V3.</li>
            </ul>
        </div>
    )
}