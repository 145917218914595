import React, { useState, useEffect, useRef } from "react";
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import './SimpleCarousel.css';

export default function SimpleCarousel({slides, numSlidesDesktop, numSlidesMobile}) {

    const [mobile, setMobile] = useState(false);

    SwiperCore.use([Autoplay])

    // mobile / desktop
    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function handleResize() {
        if (window.innerWidth < 768) {
            setMobile(true);
        }
    };

    useEffect(() => {
        if (mobile) {
            
        }
    }, [mobile]);

    return (
        <div className="simpleCarousel">
            <Swiper 
                slidesPerView={mobile ? numSlidesMobile : numSlidesDesktop}
                centeredSlides={false}
                slideToClickedSlide={true}
                spaceBetween={mobile ? 20 : 30}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false
                }}
                loop={true}
            >
                {slides.map((slideContent, index) => (
                    <SwiperSlide key={index} virtualIndex={index}>
                        {slideContent}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}