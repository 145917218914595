import React from "react";
import Hero from "../features/hero/Hero";
import Title from "../features/title/Title";
import Content from "../layouts/Content";
import ContentSubTitle from "../features/title/ContentSubTitle";
import IconContent from "../features/iconContent/IconContent";
import { Helmet } from 'react-helmet-async';
import Theme from "../theme.module.css";
import "./Services.css";

export default function WordpressDevelopment() {


    return (
        <>
            <Helmet>
                <title>Wordpress Plugin & Theme Development | Gnar Software</title>
                <meta name="description" content=">Build custom functionality with a plugin, or have your design realised pixel perfectly as a custom theme. With a custom developed theme, we can ensure you core-web vitals are superb, your page load speed is incredible, your site is highly mobile optimised, and your content is easily manageable and updatable." />
            </Helmet>

            <Hero classes="services-main-hero">
                <Title title="Service: Software Development" subTitle="Wordpress Plugin & Theme Development" />
            </Hero>

            <section className={`text-section ${Theme.darkerBack}`}>
                <Content>
                    <p><b>We are seasoned full-stack Wordpress plugin and theme developers.</b></p>
                    <p>Build custom functionality with a plugin, or have your design realised pixel perfectly as a custom theme. With a custom developed theme, we can ensure you core-web vitals are superb, your page load speed is incredible, your site is highly mobile optimised, and your content is easily manageable and updatable.</p>
                    <br/><br/>

                    <ContentSubTitle title="Plugins" />

                    <p>A Wordpress plugin is a package for your desired custom functionality. We're experienced in all kinds of PHP Wordpress plugin development. From API extensions, or payment gateways, through to CRM & ERP integrations. Our approach is always agile - ensuring we deliver business benefit early on. We obsess over the detail (tidy, extensible & secure code), so that you don't have to.</p>

                    <ContentSubTitle title="Themes" />

                    <p>A custom developed Wordpress or WooCommerce theme can ensure that your site not only looks and feels better than the competition, but also has considerably improved core-web-vitals (and therefore SEO) than sites built poorly with page builders. We're big advocates of the ACF (Advanced Custom Fields) approach - your content lives in custom fields and is very easily manageable, and then templates are built specifically for your content. Also, the Gutenberg editor - the most recent official Wordpress page editor, which enables us to build and use custom blocks, ensuring that your pages are lightweight, lean and high performing, whilst still being easily editable.</p>

                    <ContentSubTitle title="Get in touch" />
                    
                    <p>If you want to discuss your project, or pricing for any of our development services, please get in touch. We're happy to chat!</p>

                    <br/><br/>

                    <a href="tel:01483361151" className={Theme.clickToCall}>+44 (0) 1483 361 151</a>

                    <div className={Theme.spacerBottom}></div>

                </Content>
            </section>
        </>
    );
}