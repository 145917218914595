import React from "react";
import "./contentSubTitle.css";

export default function Title({title, classes}) {

    return (
        <div className={`content-subtitle-block ${classes}`}>
            <h3>{title}</h3>
        </div>
    )
}