import React, {useEffect, useState} from "react";
import Content from "./Content";
import "./Modal.css";
import "../animations.css";


export default function Modal({modalInner, setModalOpen, slug}) {

    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        setTimeout(function() {
            document.body.classList.add('modalActive');
            setFadeIn(true);
        }, 200);
    }, [])

    function closeModal() {
        setFadeIn(false);

        setTimeout(function() {
            setModalOpen(false);
            document.body.classList.remove('modalActive');
        }, 500);
    }

    return (
        <div className={`modal ${fadeIn ? 'fadeIn' : ''} ${slug}`}>
            <div className="modalScroll">
                <div className="modalInner">
                    <a onClick={closeModal} className="modalClose">
                        <img src={require('../assets/close_cross.svg').default} alt="close" />
                    </a>
                    {modalInner}
                </div>
            </div>
        </div>
    );
}