import React from "react";
import Style from "./SubMenu.css";

export default function SubMenu({setSubMenuActive, classes, children}) {

    return (
        <div className={`subMenu ${classes}`} onMouseLeave={() => setSubMenuActive('')}>
            {children}
        </div>
    );
}