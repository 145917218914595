import React from "react";
import Hero from "../features/hero/Hero";
import Title from "../features/title/Title";
import Content from "../layouts/Content";
import ContentSubTitle from "../features/title/ContentSubTitle";
import IconContent from "../features/iconContent/IconContent";
import Theme from "../theme.module.css";
import { Helmet } from 'react-helmet-async';
import "./HeadlessWooCommerce.css";

export default function HeadlessWooCommercePage() {



    return (
        <>
            <Helmet>
                <title>Headless WooCommerce | Gnar Software</title>
                <meta name="description" content="Unlock international markets, or scale for demand, with our headless Woocommerce implementation. Seamlessly scale your WooCommerce store to accommodate a growing product catalog or increased traffic." />
            </Helmet>

            <Hero classes="headless-woocom-main-hero">
                <Title title="Solution: Headless WooCommerce" subTitle="WooCommerce Scaling & Internationalisation" />
            </Hero>

            <section className={`text-section ${Theme.darkerBack}`}>
                <Content>
                    <p><b>Unlock international markets, or scale for demand, with our headless Woocommerce implementation.</b></p>
                    <p>As a natural stepping stone from your existing Woocommerce store towards an enterprise level MACH approach, decoupling your front ends from your existing WooCommerce store can allow your business to sell internationally, handle more traffic and manage multiple branded store fronts, as well as improving SEO & UX.</p>
                    <br/><br/>

                    <div className={`${Theme.flexRow} ${Theme.topicQuestionCont}`}>
                        <a className={Theme.topicQuestion}>What is MACH?</a>
                        <a className={Theme.topicQuestion}>What is headless?</a>
                    </div>

                    <ContentSubTitle title="Internationalisation" />

                    <IconContent>
                        <div>
                            <img src={require('../assets/icon-globe.svg').default} alt="globe-icon" />
                            <p>Automated Product & Front-end Translation Service</p>
                        </div>
                        <div>
                            <img src={require('../assets/icon-package.svg').default} alt="package-icon" />
                            <p>Manage Multiple Warehouses: Stock Levels & Fulfillment</p>
                        </div>
                        <div>
                            <img src={require('../assets/icon-store-fronts.svg').default} alt="store-fronts-icon" />
                            <p>Regional Localised Websites & Multiple Branded Store-fronts</p>
                        </div>
                        <div>
                            <img src={require('../assets/icon-currency-euro.svg').default} alt="currency-euro-icon" />
                            <p>Manage Localised Currencies, Taxes, Shipping Rates & Pricing</p>
                        </div>
                    </IconContent>

                    <ContentSubTitle title="Scaling" />

                    <p><b>Seamlessly scale your WooCommerce store to accommodate a growing product catalog or increased traffic.</b></p>
                    <p>Our lean and optimised implementation takes WooCommerce far beyond expectation in terms of site speed, and high traffic capability. Handling only the essential e-commerce layer functionality server side, means that your online store can handle large and complex product catalogs or sudden spikes in traffic without breaking a sweat.</p>

                    <ContentSubTitle title="Headless WooCommerce vs Headless Shopify" />

                    <div className={Theme.contentTableCont}>
                        <table className={`${Theme.contentTable} comparison-table`}>
                            <tr>
                                <th></th>
                                <th>Headless Shopify Plus <br/>(Pricing Nov 2023)</th>
                                <th>Headless WooCommerce</th>
                            </tr>

                            <tr>
                                <td className="td-bold">Implementation cost</td>
                                <td>Cost of front end development</td>
                                <td>Cost of front end development</td>
                            </tr>

                            <tr>
                                <td className="td-bold">Subscription / License</td>
                                <td>Starts at £19,000 pa, dependant on revenue</td>
                                <td className="td-green">No cost</td>
                            </tr>

                            <tr>
                                <td className="td-bold">Transaction Fees</td>
                                <td>0.15 - 0.3% + payment processing fees</td>
                                <td className="td-green">Payment processing fees only</td>
                            </tr>

                            <tr>
                                <td className="td-bold">Number of allowed brands</td>
                                <td>One per account</td>
                                <td className="td-green">Unlimited</td>
                            </tr>

                            <tr>
                                <td className="td-bold">Number of allowed store-fronts</td>
                                <td>9 and then additional cost per store</td>
                                <td className="td-green">Unlimited</td>
                            </tr>

                            <tr>
                                <td className="td-bold">Flexibility / custom <br/>functionality</td>
                                <td>Extensible within confines</td>
                                <td className="td-green">Unlimited extensibility & customisation opportunity</td>
                            </tr>

                            <tr>
                                <td className="td-bold">Integrations & connectors</td>
                                <td>Big ecosystem of connectors and extensions</td>
                                <td>Big ecosystem of connectors and extensions</td>
                            </tr>

                            <tr>
                                <td className="td-bold">Hosting</td>
                                <td className="td-green">SaaS (Your data and applications are on Shopify's servers)</td>
                                <td className="td-green">Self hosted (Your data and applications are on your own servers)</td>
                            </tr>
                        </table>
                    </div>

                    <ContentSubTitle title="Reasons To Switch To Headless WooCommerce" />

                    <div className="reasons-to-switch-cont">
                        <ul className={Theme.contentBullets}>
                            <li>You already have a WooCommerce store and you want reach sell to international markets</li>
                            <ul>
                                <li>Headless is the most flexible, scalable & international SEO friendly way of internationalisation</li>
                                <li>Our solution has inbuilt product catalog translation & static content translation service </li>
                            </ul>

                            <li>You already have a WooCommerce store and you want to speed up your site / improve UX</li>
                            <ul>
                                <li>Switching to a client side rendered website is 10x improvement for site speed, UX and core web vitals.</li>
                                <li>Our back-end implementation is lean and highly optimised, ensuring the sites are fast, efficient and can handle high loads.</li>
                            </ul>

                            <li>You already have multiple WooCommerce stores or plan to expand to have multiple stores, and you want to centralised order processing and catalog management.</li>
                            <ul>
                                <li>A headless approach allows you to have to multiple front-ends (websites), with one back-end (WooCommerce instance). The different front-ends can sell different lines, be in different languages and currencies, or appear as different brands. It's really flexible!</li>
                            </ul>

                            <li>You don't want to pay high licence or transaction fees, or face inflexibility by a SaaS ecommerce solution</li>
                            <ul>
                                <li>WooCommerce is open source (free), and we don't have any on going licence or transaction costs attached with our implementation. We charge for the setup / development work, that's it.</li>
                            </ul>
                        </ul>
                    </div>

                    <div className={Theme.spacerBottom}></div>

                </Content>
            </section>
        </>
    );
}