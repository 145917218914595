import React from "react";
import Style from "./ProductSlide.module.css";
import Theme from "../../../theme.module.css";


export default function GoCardless() {

    return (
        <div>
            <div className={`${Theme.columns} animateColumns ${Style.goCardlessSlide}`}>
                <div className={`${Theme.textCont} ${Style.productImageCont}`}>
                    <img src={require('../../../assets/ibp-link-customer-en-gb.png')} alt="laptop" />
                </div>
                <div style={{textAlign: "right"}}>
                    <div className={Style.productSelectBtnCont}>
                        <a>Freemium</a>
                    </div>
                    <h1 Style={{textAlign: "right"}}>Instant Bank Pay via GoCardless for WooCommerce</h1>
                    <div className={`${Theme.textCont} ${Style.textCont}`}>
                        <p>Take instant bank payments on your WooCommerce store through open banking technology. Increase your conversions, reduce fees, reduce failed payments and modernise your customers checkout experience.</p>
                    </div>
                    <div>
                        <a href="https://wordpress.org/plugins/wc-gocardless-instant-bank-payments/#description" target="_blank" className={Style.readMore}>Read more</a>
                    </div>
                    <a href="https://downloads.wordpress.org/plugin/wc-gocardless-instant-bank-payments.1.3.0.zip" className={Theme.greenButton}>download</a>
                </div>
            </div>
        </div>
    );
}