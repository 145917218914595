import React from "react";
import Style from "./IconContent.module.css";

export default function IconContent({children}) {

    return (
        <div className={Style.iconContent}>
            {children}
        </div>
    )
}