import React from "react";
import Content from "../layouts/Content";
import "./NotFound.css";


export default function NotFound() {

    return (
      <>
        <section className="text-section not-found">
            <Content>
                <h1>Sorry - this page can't be found</h1>
            </Content>
        </section>
      </>  
    );
}