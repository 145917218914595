import React, {useState} from "react";
import Theme from "../../theme.module.css";
import "./ContactForm.css";
import axios from "axios";


export default function ContactForm({setModalOpen}) {

    const client = axios.create({
        withCredentials: true
    })

    const contactServiceUrl = 'https://store.gnar.co.uk/wp-json/gshw/v1/contact-form';
    // const contactServiceUrl = 'http://localhost/kadamentwp1/wp-json/gshw/v1/contact-form';
    const [validationMsg, setValidationMsg] = useState('');

    const [fields, setFields] = useState({
        name: '',
        email: '',
        message: ''
    });

    function handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        setFields({...fields, [name]: value});
    }

    async function handleSubmit(event) {
        event.preventDefault();

        console.log(fields);

        try {
            const response = await client.post(contactServiceUrl, fields);
            console.log(response.data);
      
            // Reset the form
            setFields({
              name: '',
              email: '',
              message: ''
            });

            // show success message
            setValidationMsg('Thanks for your message, we will be in touch soon.');
            

        } catch (error) {
            // Handle any errors
            console.error(error);
            setValidationMsg('Sorry, there was a problem sending your message. Please email us at <a href="mailto:info@gnar.co.uk">info@gnar.co.uk</a>');
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <label>
                    Name
                    <input type="text" name="name" onChange={handleChange} value={fields.name} />
                </label>
                <label>
                    Email
                    <input type="email" name="email" onChange={handleChange} value={fields.email} />
                </label>
                <label>
                    Message
                    <textarea name="message" onChange={handleChange} value={fields.message}></textarea>
                </label>
                <input type="submit" className={Theme.greenButtonSubmit} style={{marginTop: "20px", display: "inline-block"}} value="send"/>

                {validationMsg && 
                    <p className="contactFormValidationMsg">{validationMsg}</p>
                }
            </form>
        </>
    );
}